<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <client titleName="我的客户"></client>
  </div>
</template>
<script>
  import client from '@/views/common/client.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  export default {
    name: 'clientList',
    components: {
      client,
      breadcrumb
    },
    data() {
      return {
        breadStaticData:['承诺达标合格证管理', '我的客户'],
        linkData:[],
        thirdStaticData:[],
        contHeight: ''
      }
    },
    created() {
    },
    mounted(){
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
</style>