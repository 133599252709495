<template>
  <div :class="isDialog ? '' : 'page-table'">
    <div class="title-search my-table-list">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <div class="title-search-line">
          <el-form-item label="客户名称:">
            <el-input size="small" v-model="searchForm.customerName" placeholder="请输入客户名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="统一信用代码或身份证:">
            <el-input size="small" v-model="searchForm.creditCode" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机:">
            <el-input size="small" v-model="searchForm.phone" placeholder="请输入手机" clearable></el-input>
          </el-form-item>
          <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          <el-button v-if="isDialog" type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </el-form>
    </div>
    <div class="title-tab" v-if="!isDialog">
      <div class="title-tab-btns">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
      </div>
    </div>
    <el-table 
      ref="table" 
      class="my-table"
      size="small" 
      :data="listData" 
      highlight-current-row 
      border
      v-loading="loading"  
      :element-loading-text="loadtext" 
      :height="isDialog ? 'calc(100vh - 367px)' : tableHeight"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <el-table-column align="center" type="index" width="70" label="序号">
        <template slot-scope="scope">
          <span>{{
            pageparm
              ? (pageparm.page - 1) * pageparm.limit +
                scope.$index +
                1
              : scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      
      <el-table-column
        v-for="(headData, index) in tableHead"
        :key="index"
        :prop="headData.prop"
        :label="headData.label"
        :width="headData.width"
        :align="headData.align || 'center'"
        :header-align="headData.headerAlign || 'center'"
        :min-width="headData.minWidth"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          {{ scope.row[headData.prop] || '' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="180">
        <template slot-scope="scope">
          <el-button v-if="isDialog" size="small" type="text" @click="choose(scope.row, scope.$index)">选择</el-button>
          <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
          <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
          <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    <client-edit ref="editForm" @getData="getData" :tempType="tempType"></client-edit>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import clientEdit from './clientEdit.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  let title = ''
  export default {
    name: 'client',
    components: {
      pagination,
      breadcrumb,
      clientEdit
    },
    props:{
      tempType: {
        type: String,
        default: '01'
      },
      titleName: {
        type: '',
        default: ''
      },
      isDialog: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        timeRange: '',
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          customerBodyId: utils.getBodyId(),
          customerName: '',
          creditCode: '',
          phone: ''
        },
        urlName: 'commoncustomerpage',
        tableHead: [{
          prop: 'customerName',
          label: '客户名称'
        },{
          prop: 'areaName',
          label: '所属区域'
        },{
          prop: 'creditCode',
          label: '统一信用代码或身份证',
          width: 180
        },{
          prop: 'jointPhoneNumber',
          label: '手机',
          width: 180
        }],
        initForm: {
          customerBodyId: '',
          customerName: '',
          jointMan: '',
          jointPhoneNumber: '',
          email: '',
          address: '',
          remark: '',
          bodyId: utils.getBodyId()
        },
        loadtext: '加载中',
        pageName: title,
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        }
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
      this.pageName = this.titleName
    },
    methods: {
      choose(data, index){
        this.$emit('getclient', data)
      },
      reTable(){
        this.$nextTick(() => {
          this.$refs.table &&  this.$refs.table.doLayout()
        })
      },
      async initTable(){
        this.getData()
      },
      async add(){
        // let id = await utils.getNewId(),
        let obj = {
          ...this.initForm
        }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add', obj)
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data, index){
        let _this = this
        request.commoncustomerDetail(data.customerId).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      del(data, index){
        let _this = this
        _this.$confirm(`确认要删除${data.customerName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.commondeletecustomer([data.customerId]).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                areaName: (item.province || '') + (item.city || '') + (item.county || '') + (item.town || '') + (item.village || '')
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
</style>